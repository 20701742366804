import React from 'react'
import { Helmet } from 'react-helmet'
import '../theme/bootstrap-grid.css'
import {
  Box,
  Button,
  Card,
  Container,
  ContactForm,
  Flex,
  GoogleMap,
  Image,
  Link,
  Layout,
  Text
} from '../components'

const Contact: React.FC<unknown> = () => {
  return (
    <Layout>
      <Helmet>
        <title>Incobrasa | Contact</title>
        <meta name="description" content="Contact Incobrasa Industries" />
      </Helmet>
      <GoogleMap
        address="540 East US Highway 24 Gilman, Illinois 60938"
        height="400"
      />
      <Box mt="xxlarge" mb="100">
        <Container>
          <Flex wrap={['wrap-reverse', 'wrap-reverse', 'wrap']}>
            <Box width={[1, 1, 1 / 2]} mb={['xlarge', 'xlarge', 'none']}>
              <ContactForm />
            </Box>
            <Box
              width={[1, 1, 1 / 2]}
              pl={['none', 'none', 'xxlarge']}
              mb={['xxlarge', 'xxlarge', -80]}
            >
              <Card position="relative">
                <Text
                  textTransform="uppercase"
                  mb="large"
                  weight="bold"
                  textAlign="center"
                >
                  Incobrasa Industries, Ltd.
                </Text>
                <Flex alignItems="center" mb="large">
                  <Image
                    src="/img/phone-primary-circle-icon.svg"
                    alt="phone"
                    width="50"
                    height="50"
                    mr="medium"
                  />
                  <Link
                    size={['large', 'large', 'large', 'large', 'xlarge']}
                    href="tel:+18152654803"
                    textDecoration="none"
                    color="primary"
                  >
                    (815) 265-4803
                  </Link>
                </Flex>
                <Flex alignItems="center">
                  <Image
                    src="/img/mail-primary-circle-icon.svg"
                    alt="phone"
                    width="50"
                    height="50"
                    mr="medium"
                  />
                  <Link
                    size={['large', 'large', 'large', 'large', 'xlarge']}
                    href="mailto:info@incobrasa.com"
                    textDecoration="none"
                    color="primary"
                  >
                    info@incobrasa.com
                  </Link>
                </Flex>
                <Box as="hr" my="large" border="none" height="1" bg="#bbbbbb" />
                <Text
                  size="large"
                  weight="bold"
                  textTransform="uppercase"
                  mb="medium"
                >
                  Address
                </Text>
                <Text mb="large">
                  540 East US Highway 24
                  <br />
                  Gilman, Illinois 60938
                </Text>
                <Button
                  variant="secondary"
                  textTransform="uppercase"
                  display="block"
                  mx="auto"
                  py="medium"
                  size="large"
                  width="290"
                  maxWidth="100%"
                  target="_blank"
                  href="https://www.google.com/maps/place/Incobrasa+Industries+Ltd/@40.76202,-88.0168014,17z/data=!3m1!4b1!4m5!3m4!1s0x880da540e54ae441:0x68d79bc98a1c1b3a!8m2!3d40.762016!4d-88.0146074"
                >
                  Get Directions
                </Button>
              </Card>
            </Box>
          </Flex>
        </Container>
      </Box>
    </Layout>
  )
}

export default Contact
